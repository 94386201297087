const NotFound = () => {
  return (
    <svg
      fill="#000000"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 16 16"
      width="16px"
      height="16px"
    >
      <path d="M 9.726563 1.023438 L 9.023438 1.726563 L 10.792969 3.5 L 9.023438 5.273438 L 9.726563 5.976563 L 11.5 4.207031 L 13.269531 5.976563 L 13.980469 5.273438 L 12.207031 3.5 L 13.980469 1.726563 L 13.269531 1.023438 L 11.5 2.792969 Z M 2.882813 3.007813 C 2.585938 3.03125 2.300781 3.179688 2.125 3.457031 C 0.421875 6.109375 0.691406 9.664063 3.015625 11.984375 C 5.335938 14.308594 8.886719 14.578125 11.542969 12.875 C 12.09375 12.523438 12.140625 11.738281 11.691406 11.289063 L 8.734375 8.328125 C 8.898438 8.09375 9 7.8125 9 7.5 C 9 6.671875 8.328125 6 7.5 6 C 7.1875 6 6.90625 6.101563 6.671875 6.265625 L 3.710938 3.304688 C 3.488281 3.082031 3.179688 2.980469 2.882813 3.007813 Z M 2.964844 4 C 2.964844 3.996094 2.988281 3.996094 3.003906 4.011719 L 10.984375 11.996094 C 11.007813 12.015625 11.003906 12.03125 11 12.03125 C 8.726563 13.488281 5.707031 13.265625 3.71875 11.28125 C 1.734375 9.292969 1.507813 6.273438 2.964844 4 Z" />
    </svg>
  );
};

export default NotFound;
